img{
    width:100%;
}

canvas{
    height:100%;
    width:100%;
}
main{
    width:100%;    
}
