.text {
    font-weight: 400;
}
.white-text {
    color: #F8F8F8;
}

.grey-text {
    color: #AFAFAF;
}

.black-text {
    color: #2B2B2B;
}

.blue-text {
    color: #6584CC;
}

.sm-text {
    font-size: 1.25rem;
}

.md-text {
    font-size: 1.50rem;
}

.lg-text,
.x-md-text,
.x-lg-text,
.x2-lg-text,
.x3-lg-text,
.title {
    font-weight: 700;
    display: inline-block;
}

.x-md-text {
    font-size: 2rem;
}

.lg-text {
    font-size: 2.5rem;
}

.x-lg-text {
    font-size: 3rem;
}

.x2-lg-text {
    font-size: 3.5rem;  
}

.x3-lg-text {
    font-size: 4.5rem;
}

.text-opacity {
    opacity: 0.5;
}




@media screen and (max-width: 1650px) {
    .sm-text {
        font-size: 1.25rem;
    }

    .lg-text {
        font-size: 2.5rem;
    }

    .x-lg-text {
        font-size: 3rem;
    }

    .x2-lg-text {
        font-size: 3.5rem;
    }

    .x3-lg-text {
        font-size: 4.5rem;
    }

    .md-text {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 1400px) {
    .sm-text {
        font-size: 1.25rem;
    }

    .lg-text {
        font-size: 2.5rem;
    }

    .x-lg-text {
        font-size: 3rem;
    }

    .x2-lg-text {
        font-size: 3rem;
    }

    .x3-lg-text {
        font-size: 4.5rem;
    }

    .md-text {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 1200px) {
    .sm-text {
        font-size: 1rem;
    }

    .lg-text {
        font-size: 2.5rem;
    }

    .x-lg-text {
        font-size: 3rem;
    }

    .x2-lg-text {
        font-size: 2.5rem;
    }

    .x3-lg-text {
        font-size: 4.5rem;
    }

    .md-text {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 900px) {
    .sm-text {
        font-size: 1rem;
    }

    .lg-text {
        font-size: 2rem;
    }

    .x-lg-text {
        font-size: 1.5rem;
    }

    .x2-lg-text {
        font-size: 2.20rem;
    }

    .x3-lg-text {
        font-size: 2.4rem;
    }

    .md-text {
        font-size: 1.25rem;
    }
}
