
.inscriptions-container {
    background-image: url('../../assets/img/background_CCIF.png');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    .section-entreprises {
        margin-bottom:80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .first {
            width: 76.5%;
            margin-top: 40px;
        }
    }
}
.fribourgOpen-illustration{
    display:block;
    width:400px;
    position:absolute;
    right:0;
    margin-top:80px;
}
.fribourgOpen-illustration-mobile{
    width:400px;
    margin-top:80px;
display:none;
}
.title {
    display: flex;
    flex-direction: column;
}


.content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .paragraph {
        align-self: flex-start;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        p {
            margin-top: 40px;
        }
    }
    .logo-container {
        align-self: flex-end;
    }
}


.formulaire {
    margin-top: 40px;
    .formulaire-p {
        margin-top: 24px;
    }
    a {
        color: #6584CC;
        button p {
            color: #6584CC;
        }
    }
}


@media screen and (max-width: 1200px) {
    .fribourgOpen-illustration{
        display:none;
    }
    .fribourgOpen-illustration-mobile{
        width:350px;
        margin-top:80px;
    display:block;
    }
    .inscriptions-container {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        .section-entreprises {
            padding-top: 80px;
            margin-bottom:0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .first {
                width: 76.5%;
                margin-top: 40px;
            }
        }
    }


    .title {
        display: flex;
        flex-direction: column;
    }


    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .paragraph {
            align-self: flex-start;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            p {
                margin-top: 40px;
            }
        }
        .logo-container {
            align-self: flex-end;
            width: auto;
            margin-top: 40px;
        }
    }


    .formulaire {
        margin-top: 40px;
        .formulaire-p {
            margin-top: 24px;
        }
        a {
            color: #6584CC;
            button p {
                color: #6584CC;
            }
        }
    }
}