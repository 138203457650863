.b-700 {
    font-weight: 700;
}
.question-container {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.75rem 1.75rem;
    margin-bottom: 1rem;
    border-radius: 0.625rem;
    border: 2px solid #ECECEC;
    svg {
        width: 24px;
        height: auto;
    }
}
.question-header{
    display:flex;
    width:100%;
    justify-content: space-between;
}

@media screen and (max-width: 1650px) {
    
}

@media screen and (max-width: 1400px) {
    .question-container {
        width: 100%;
        svg {
            width: 24px;
            height: auto;
        }
    }
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 900px) {
    .question-container {
        width: 100%;
        gap: 24px;
        svg {
            min-width: 24px;
            height: auto;
           
        }
    }
}
