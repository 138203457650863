.download-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/img/background_CCIF.png');
    // overflow-y: hidden;
    width: 100vw;


    .section-a {
        width: 76.5%;
        margin: 100px 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > p {
            align-self: center;
            margin-bottom: 5rem;
        }
        .first {
            display: flex;
            justify-content: space-between;
            align-self: flex-start;
            width: 82%;
            .title {
                width: 33.25%;
            }
            .paragraph {
                width: 50%;
            }
            
        }
        .second {
            width: 62.5%;
            display: flex;
            align-self: center;
            justify-content: space-between;
            margin: 4.7rem 0 3rem 0;


            .android-container, 
            .ios-container {
                width: 48.25%;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 3.5rem 0;
                gap: 2rem;
                .title-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1rem;
                }
                .information-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 71%;
                    gap: 9rem;
                    p {
                        text-align: center;
                    }
                    img {
                        width: 50%;
                    }

                }
            }

            .android-container {
                background-color: #FFFFFF;
            }
            .ios-container {
                background-color: #6584CC;
            }


        }
    }
}


@media screen and (max-width: 1650px) {
    .download-container {

        .section-a {
            padding-top: 80px;
            margin: 75px 0 0 0;
            > p {
                margin-bottom: 3.9rem;
            }
            .second {
                margin: 2rem 0 2.3rem 0;
                width: 100%;
                .android-container, 
                .ios-container {
                    padding: 2.75rem 0;
                    gap: 1.25rem;
                    .title-container {
                        gap: 0.5rem;
                    }
                    .information-container {
                        gap: 4rem;
                    }
                }
            }
        }
    }
}





@media screen and (max-width: 1400px) {
    .download-container {

        .section-a {
            .first {
                width: 100%;
                gap: 5%;
                .title,
                .paragraph {
                    width: 50%;
                }
            }
        }
    }
}
@media screen and (max-width: 1200px) {}
@media screen and (max-width: 900px) {
    .download-container {

        .section-a {
            width: 85%;
            .first {
                width: 100%;
                gap: 5%;
                flex-direction: column;
                .title,
                .paragraph {
                    width: 100%;
                }
                .title {
                    margin-bottom: 16px;
                }
            }
            .second {
                flex-direction: column;
                .android-container, 
                .ios-container {
                    width: 100%;
                }
                .android-container {
                    margin-bottom: 36px;
                }
            }
        }
    }
}
