.company-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #F8F8F8;
    border-bottom: 1px #E6E6E6 solid;
}

.company {
    width: 76.5%;
    padding: 132px 0;
    .title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 24px;
        p {
            display: block;
            margin-right: 20px;
        }
    }
}


.company-list {
    p {
        text-align: center;
        line-height: 28px;
    }
}

@media screen and (max-width: 1200px) {
    .company-list {
        p {
            text-align: center;
            line-height: 24px;
        }
    }
    .company {
        .title {
            p {
                margin-right: 14px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .company-list {
        p {
            text-align: center;
            line-height: 24px;
        }
    }
    .company {
    padding: 80px 0;

        .title {
            flex-direction: column;
            p {
                margin-bottom: 0;
            }
        }
    }
}