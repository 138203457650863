.practical-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F8F8F8;
    border-top: 1px #E6E6E6 solid;
    section {

        width: 76.5%;
        padding: 132px 0 132px 0;
        .pratical-info-content {
            margin-top: 32px;
            display: flex;
            flex-wrap: wrap;
            .infos-question {
                width: 50%;
                margin-bottom: 16px;
            }

        }
    }
}
@media screen and (max-width: 1200px) {
    .practical-info-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        section {
    
            width: 76.5%;
            padding: 80px 0 80px 0;
            .pratical-info-content {
                margin-top: 32px;
                display: flex;
                flex-wrap: wrap;
                .infos-question {
                    width: 100%;
                    margin-bottom: 16px;
                }

            }
        }
    }
}
