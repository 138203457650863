nav {
    background-color: rgba(248,248,248, 1);
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 3.95vw;
    border: 1px solid #E6E6E6;
    gap: 3rem;
    z-index: 2;
}

.logo-container {
    gap: 2rem;
}

.btns-container {
    width: 100%;
    gap: 2rem;
    display: flex;
    flex-direction: row;
}

.cn-1,
.cn-2 {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.cn-2 {
    min-width: fit-content;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    
}

.cn-1 {
    width: 100%;
    justify-content: flex-start;
}

.burger-menu {
    display: none;
}


@media screen and (max-width: 1200px) {
    nav {
        position: fixed;
        top: 0;
        background-color: rgba(248,248,248, 1);
        width: 100vw;
        height: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 3.95vw;
        border: 1px solid #E6E6E6;
        gap: 3rem;
        
    }
    
    .logo-container {
        gap: 2rem;
    }
    .btns-container {
        display: none;
    }
    .btns-container.visible {
        position: fixed;
        top: 80px;
        left: 0;
        width: 100%;
        height: 100%;
        gap: 2rem;
        display: flex;
        flex-direction: column;
        background-color: rgba(248,248,248, 1);
        
    }
    
    .cn-1,
    .cn-2 {
        padding: 0 24px;
        // height: 50%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    
    .cn-1 {
        padding-top: 60px;
        width: 100%;
        justify-content: flex-start;
        opacity: 0.5;
        a {
            padding: 0;
            border-bottom: 1px solid #323232;
            
            p {
                font-size: 24px;
            }
        }
    }

    .cn-2 {
        min-width: fit-content;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }
    
    
    .burger-menu {
        display: block;
        align-items: flex-end;

    }
}




