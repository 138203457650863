* {
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
    box-sizing: border-box;
}


html {
    font-size: 16px;
}

// @media screen and (max-width: 1650px) {
//     html {
//         font-size: 12px;      
//     }
// }

// @media screen and (max-width: 1400px) {
//     html {
//         font-size: 12px;      
//     }
// }

// @media screen and (max-width: 1200px) {
//     html {
//         font-size: 11px;      
//     }
// }
// @media screen and (max-width: 800px) {
//     html {
//         font-size: 11px;      
//     }
// }



body {
    background: rgb(245,245,245);
    overflow-y:scroll;
    overflow-x: hidden;
}
.mapboxgl-control-container{
    display:none;
}

.App {
display:flex;
}


#map{
    height:100%;
    width:100%;
}



.content{
    margin:8px;
    background:white;
    height:calc(100vh - 96px);
    border-radius: 10px;
    position:relative;

}

.map{
    display:flex;
    height:100%;
    width: 100%;
    border-radius: 10px;
}
.content{
    display:flex;
}
a{
    text-decoration: none;
}