.questions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/img/background_CCIF.png');
    overflow-y: hidden;
    width: 100vw;

    .section-question {
        width: 76.5%;
        margin: 8rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .first {
            width: 12.25%;
            display: flex;
            flex-direction: column;
        }
        .second {
            width: 87.25%;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            
            .question-content {
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                align-self: flex-start;
                margin-bottom: 3.25rem;

                .question-title {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}



@media screen and (max-width: 1650px) {
    .questions-container {
        .section-question {
            width: 92%;
            margin: 6rem 0;
            .second {
                .question-content {
                    margin-bottom: 2.5rem;

                    .question-title {
                        margin-bottom: 1.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .questions-container {
        .section-question {
            width: 85%;
            flex-direction: column;
            row-gap: 64px;
            .first {
                width: 88.25%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 5%;

            }
            .second {
                width: 88.25%;
                display: flex;
                align-items: center;
                flex-direction: column;
                
                .question-content {
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    align-self: center;
                    margin-bottom: 3.25rem;
    
                    .question-title {
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .section-question {
        padding-top: 80px;
    }
}

@media screen and (max-width: 900px) {
    .questions-container {
        .section-question {
            flex-direction: column;
            .first {
                width: 100%;
                display: flex;
                // flex-direction: column;
                // justify-content: space-between;
                // flex-wrap: wrap;
                gap: 5%;
                // .navlink {
                //     width: 100%;
                // }
            }
            .second {
                width: 100%;
                .question-content {
                    width: 100%;
                }
            }
        }
    }
}