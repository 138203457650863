.navlink {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    text-decoration: none;
    .navlink_text {
        margin-left: 1rem;
        font-size: 1.25rem;
    }
    
    .navlink_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5rem;
        height: 1.5rem;
    }
}

