.btn {
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    height: 53px;
    border: none;
    outline: none;
    width: fit-content;
    white-space: nowrap;
}

.btn-primary {
    background-color: #6584CC;
    padding: 1rem 2rem;
    transition: all 0.5s;
}

.btn-primary:hover{
    padding: 1rem 2.5rem;
    box-shadow: 0 0 8px 4px rgba(101, 132, 204,0.4);
}

.btn-primary p {
    color: #F8F8F8;
    // color: black;
    font-weight: 400;
}

.btn-secondary,
.btn-tertiary  {
    background-color: #F8F8F8;
    padding: 16px 32px;
}
.btn-secondary p{
    color: #6584CC;
    font-weight: 400;
}

.btn-tertiary p {
    font-weight: 400;
}

.btn-outline, 
.btn-outline-secondary {
    padding: 16px 0;
    background-color: transparent;
}

.btn-outline p {
    font-weight: 400;
    padding: 0;
}

.btn-outline-secondary p {
    color: #6584CC;
    font-weight: 400;
    padding: 0; 
}

.btn-row .icon {
    margin-left: 8px;
    margin-right: 0;
}

.btn-row-reverse {
    flex-direction: row-reverse;
}

.btn-row-reverse .icon {
    margin-right: 8px;
    margin-left: 0;
}

@media screen and (max-width: 1400px) {
    .btn {
        height: 37.26px;
    }
}

.navlink.active p{
    color : #6584CC;
    opacity: 1;
}